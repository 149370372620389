// ------ COlor
$white: #fff;
$black: #131517;
$grey: #ddd;
$grey-light: #f3f3f5;
$red: #e34e47;
$blue: #409ad7;
$green: #34ba9c;
$yellow: #efc241;
$bg-color: #fdfdfd;

// ------ Breakpoints
$breakpoint-md: 600px;
$breakpoint-lg: 900px;
$breakpoint-xl: 1200px;
$breakpoint-xxl: 1800px;
$breakpoint-xxxl: 2100px;

@mixin md() { @media (min-width: $breakpoint-md) { @content; } }
@mixin lg() { @media (min-width: $breakpoint-lg) { @content; } }
@mixin xl() { @media (min-width: $breakpoint-xl) { @content; } }
@mixin xxl() { @media (min-width: $breakpoint-xxl) { @content; } }
@mixin xxxl() { @media (min-width: $breakpoint-xxxl) { @content; } }

html {
  font-size: 10px;

  @include xxl {
    font-size: 12px;
  }

  @include xxxl {
    font-size: 14px;
  }
}

body {
  background: $bg-color;
  font-size: 1.8rem;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
}

a,
a:active,
a:visited,
a:focus {
  border-bottom: 1px solid $grey;
  color: $black;
}

a:hover {
  color: $red;
}

code {
  background: none;
  border: 0;
  font-size: 0.9em;
  padding: 0;
}

h1 {
  color: $blue;
  margin-bottom: 5rem;
}

h2,
h3,
h4 {
  margin-top: 5rem;
}

.Examples {
  color: $red;
  margin-top: 2rem;

  @include md {
    margin-top: 0;
  }
}

.Content {
  //margin: 0 auto;
  max-width: 60rem;
  padding: 5rem 2rem;
  position: relative;
  text-align: center;
  width: 100%;
  color: #222d3b;
}

.Address {
 font-size: 0.9em;
}

.Footer {
  background: $grey-light;
  margin-top: 0rem;
  padding: 1vh 0;

  @include md {
    padding: 1vh 0;
  }

  h1 {
    font-size: 4em;
    margin: 0;
  }

  h2 {
    font-size: 2.4em;
    margin: 0 0 5rem;
  }

  button {
    background: none;
    border: 1px solid rgba($black, 0.25);
    border-radius: 5rem;
    color: $black;
    cursor: pointer;
    padding: 1.1rem 2rem;
    transition: border 250ms;

    &:hover,
    &:focus {
      border: 1px solid $black;
    }
  }
}

.Footer-links {
  margin: 1rem 0 5rem;

  a {
    font-size: 2rem;
    margin: .5rem;
    padding: 0.4rem 0;
  }
}

// DEMOS

.Demo {
  text-align: center;
}

.Explosion {
  height: 24rem;
  margin: 0 auto 10rem;
  position: relative;
  width: 24rem;
}

.Explosion-box {
  background: $grey;
  border-radius: 0.3rem;
  float: left;
  height: 5.4rem;
  margin: 0.3rem;
  width: 5.4rem;
}

$phone-ratio: 2.01;

.Phone {
  height: 15rem * $phone-ratio;
  margin: 0 auto;
  position: relative;
  transform: rotate(90deg) scale(0);
  width: 15rem;
}

.ordenPic {
  width: 450px;
}

.Phone-svg {
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 10;
}

.Phone-content {
  height: 74%;
  left: 8%;
  overflow: hidden;
  position: absolute;
  top: 13.5%;
  width: 85%;
  z-index: 0;
}

.Phone-contentPlx {
  background: $blue;
  height: 100%;
  width: 100%;
}

.Phone-contentSection {
  background: $red;
  color: $white;
  font-size: 3rem;
  font-weight: 300;
  height: 100%;
  padding-top: 75%;
  text-align: center;
  width: 100%;

  &:nth-child(2) { background: $blue; }
  &:nth-child(3) { background: $green; }

  @include md {
    font-size: 5rem;
  }
}

.Links {
  border-radius: 50%;
  height: 12rem;
  margin: 0 auto;
  padding: 2rem;
  position: relative;
  width: 12rem;

  @include md {
    bottom: 50px;
    position: fixed;
    right: 50px;
  }
}

.Links-plx {
  height: 100%;
  left: 0;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: opacity 250ms;
  width: 100%;
  z-index: 0;

  &.Plx--below,
  &.Plx--below + .Links-content {
    opacity: 0;
  }
}

.Links-circle {
  background: $blue;
  border-radius: 50%;
  height: 1rem;
  position: absolute;
  top: -0.5rem;
  transform: translate3d(0, 0, 0);
  width: 1rem;

  &:nth-child(1) {
    left: 50%;
    margin-left: -0.5rem;
  }

  &:nth-child(2) {
    background: $red;
    left: 0.2rem;
    top: 70%;
  }

  &:nth-child(3) {
    background: $yellow;
    right: 0.2rem;
    top: 70%;
  }
}

.Links-content {
  position: relative;
  text-align: center;
  transition: opacity 250ms;
  z-index: 10;

  a {
    display: inline-block;
    line-height: 1.2em;
    margin: 0.2rem;
  }
}

.cloudItem {
  position: relative;
  top: -60px;
}

#dataVizPic {
  width: 400px;
  position: relative;
  top: 100px;
  left: 100px;
}

#dataVizPic2 {
  width: 400px;
  position: relative;
  top: 350px;
  left: -300px;
}

#dataVizPicMobile {
  width: 150px;
  position: relative;
  top: 90px;
  left: 0px;
}

#dataVizPicMobile2 {
  width: 150px;
  position: relative;
  top: 90px;
  right: -15px;
  height: 80px
}

.dsContent {
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  text-align: center;
  margin: 10px;
  pointer-events: none;
  color: white;
  background-color: #4dc4a87a;
  width: 300px;
  border-radius: 10px;
  padding: 10px;
  height: 100px;
  position: absolute;
}

#dataVizSubtitle {
  background: #4dc4a8;
  color: var(--color-subtitle);
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.25em;
  text-indent: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 120px;
  right: 50px;
  width: 470px;
  font-size: 18px;
}

#dataVizSubtitleMobile {
  background: #4dc4a8;
  color: var(--color-subtitle);
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.3em;
  text-indent: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 60px;
  right: 0px;
  width: 300px;
  font-size: 10px;
}

#dataViz {
  font-weight: normal;
  color: var(--color-title);
  text-align: left;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 50px;
  right: 50px;
  font-size: 60px;
}

#dvMain {
  position: absolute;
  z-index: 1;
  right: 50px;
  top: 220px;
  width: 550px;
  height: 365px;
  color: #091862;
  background-color: white;
  font-size: 16px;
  border-radius: 8px;
  padding: 8px;
}

#dvMainMobile {
  position: absolute;
  z-index: 11;
  left: 0px;
  top: 220px;
  width: 320px;
  height: 400px;
  color: #091862;
  background-color: white;
  font-size: 14px;
  border-radius: 8px;
  padding: 8px;
}

#dataVizMobile {
  font-weight: normal;
  color: var(--color-title);
  text-align: left;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  right: 0px;
  font-size: 28px;
}

#ccMain {
  position: absolute;
  z-index: 1;
  right: 100px;
  top: 0px;
  width: 500px;
  background: #ffffffab;
  height: 370px;
  color: #27313f;
  border-radius: 6px;
  padding: 8px;
  font-size: 16px;
}

#ccMainMobile {
  position: absolute;
  z-index: 1;
  right: 0px;
  top: 0px;
  width: 300px;
  background: #ffffffab;
  height: 570px;
  color: #27313f;
  border-radius: 6px;
  padding: 8px;
  font-size: 16px;
}

#dsMain {
  position: relative;
  z-index: 1;
  left: 100px;
  top: 100px;
  width: 800px;
  height: 300px;
  color: #ffffff;
  font-size: 16px;
}

#dsMainMobile {
  position: relative;
  z-index: 1;
  left: 0px;
  top: 10px;
  width: 320px;
  height: 600px;
  color:#ffffff;
  font-size: 16px;
}

#dataScience {
  font-size: 70px;
  font-weight: normal;
  color: var(--color-title);
  text-align: left;
  margin: 0;
  pointer-events: none;
  position: relative;
  top: 50px;
  left: 100px;
}

#dataScienceMobile {
  font-size: 29px;
  font-weight: normal; 
  color: var(--color-title);
  text-align: left;
  margin: 0;
  pointer-events: none;
  position: relative;
  top: 20px;
  left: 0px;
}

#aiMain {
  position: absolute;
  z-index: 1;
  right: 100px;
  top: 200px;
  width: 640px;
  height: 300px;
  color: #3d516b;
  font-size: 15px;
}

#aiMainMobile {
  position: absolute;
  z-index: 1;
  right: 20px;
  top: 300px;
  width: 300px;
  height: 300px;
  color: #3d516b;
  font-size: 11px;
}

#aiImage {
  z-index: 999999;
  width: 400px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -200px;
}

#aiImageMobile {
  z-index: 999999;
  width: 350px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  margin-left: -175px;
}

#ccImage {
  z-index: 1;
  width: 674px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -337px;
}

#ccImageMobile {
  z-index: 1;
  width: 320px;
  position: absolute;
  top: 700px;
  left: 50%;
  margin-left: -160px;
}
#dvImageMobile {
  z-index: 11;
  width: 320px;
  position: absolute;
  top: 700px;
  left: 50%;
  margin-left: -160px;
}

#dvImage {
  z-index: 10;
  width: 500px;
  min-width: 470px;
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -220px;
}

#ccTitle {
  font-size: 70px;
  font-weight: normal;
  color: var(--color-title);
  text-align: left;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 30px;
  right: 100px;
}

#ccTitleMobile {
  font-size: 35px;
  font-weight: normal;
  color: var(--color-title);
  text-align: left;
  margin: 0;
  pointer-events: none;
  position: absolute;
  top: 20px;
  right: 10px;
}

#aiTitle {
  color: #3d516b;
    font-size: 65px;
    text-align: left;
    margin: 0;
    pointer-events: none;
    position: absolute;
    top: 40px;
    right: 100px;
}

#aiTitleMobile {
  color: #3d516b;
    font-size: 35px;
    text-align: left;
    margin: 0;
    pointer-events: none;
    position: absolute;
    top: 40px;
    right: 0px;
}

#aiSubtitle {
  font-size: 23px;
  background: #3d516b;
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.2em;
  text-indent: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 135px;
  right: 100px;
  width: 640px;
}

#aiSubtitleMobile {
  font-size: 12px;
  background: #3d516b;
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.2em;
  text-indent: 0.3em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 85px;
  right: 0px;
  width: 350px;
}

#ccSubtitle {
  font-size: 20px;
  background: #3d516b;
  color: var(--color-subtitle);
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.15em;
  text-indent: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 110px;
  right: 100px;
  width: 685px;
}

#ccSubtitleMobile {
  font-size: 9px;
  background:
  #3d516b;
  color: var(--color-subtitle);
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.1em;
  text-indent: 0.2em;
  font-weight: bold;
  text-transform: uppercase;
  position: absolute;
  top: 70px;
  right: 0px;
  width: 320px;
}

#dataScienceSubtitle {
  font-size: 20px;
  background: #4dc4a8;
  color: var(--color-subtitle);
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.3em;
  text-indent: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  top: 50px;
  left: 100px;
  width: 500px;
}

#dataScienceSubtitleMobile {
  font-size: 13px;
  background:#4dc4a8;
  color: var(--color-subtitle);
  padding: 0.45em 0.5em;
  line-height: 1;
  margin: 0;
  letter-spacing: 0.3em;
  text-indent: 0.5em;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  top: 20px;
  left: 0px;
  width: 320px;
}

#dataVis {
  font-size: 2.5em;
  font-weight: normal;
  color: var(--color-title);
  text-align: right;
  margin: 0;
  pointer-events: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

#triangle-topright {
  width: 600px;
    /* height: 500px; */
    right: 0px;
    border-top: 100px solid #fffcfcb7;
    border-left: 100px solid transparent;
    position: absolute;
}

.cloudContent {
  font-family: 'Playfair Display', serif;
  font-size: 16px;
  text-align: center;
  margin: 10px;
  pointer-events: none;
  color: white;
  background-color: #222d3b74;
  width: 500px;
  border-radius: 10px;
  padding: 1px;
  height: 100px;
}

.coolButton {
  background: none;
    border: 1px solid rgba(19, 21, 23, 0.25);
    border-radius: 5rem;
    color: #131517;
    cursor: pointer;
    padding: 1.1rem 2rem;
    transition: border 250ms;
}


.StickyText {
  bottom: 0;
  left: 0;
  max-width: 54rem;
  opacity: 0;
  padding: 0 2rem;
  position: fixed;
  width: 100%;

  @include md {
    left: auto;
    padding: 0;
  }

  h2 {
    margin: 0;
    transform: translateY(50%);
  }
}

.StickyText-trigger {
  margin-top: 0vh;
}
